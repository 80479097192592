import React, { useState, useEffect } from 'react';
import { Wheel } from 'react-custom-roulette';
import { Box, Typography, Button, Modal } from '@mui/material';
import { styled } from '@mui/system';
import keniFituar from "./keni-fituar.svg";
import tryAgainImage from "./TryAgain.svg";

const prizes = [
  { image: '/images/500euro.jpg', name: '500 Euro' },
  { image: '/images/tryagain.png', name: 'Try Again' },
  { image: '/images/bicycle.jpg', name: 'Bicycle' },
  { image: '/images/car.jpg', name: 'Car' },
  { image: '/images/laptop.jpg', name: 'Laptop' },
  { image: '/images/smartphone.jpg', name: 'Smartphone' },
  { image: '/images/vacation.jpg', name: 'Vacation' },
  { image: '/images/tv.jpg', name: 'TV' },
  { image: '/images/giftcard.jpg', name: 'Gift Card' },
  { image: '/images/Headphones.jpg', name: 'Headphones' },
  { image: '/images/Watch.jpg', name: 'Watch' },
  { image: '/images/Tablet.jpg', name: 'Tablet' },
];

const getColorByIndex = (index) => {
  const colors = [
    { background: '#000', text: '#FFD700' }, // Black background with white text
    { background: '#F33838', text: '#000000' }, // Red background with white text
    { background: '#FACC3F', text: '#000000' }, // Yellow background with black text
    { background: '#F33838', text: '#FFFFFF' }, // Red background with white text
    { background: '#FACC3F', text: '#000000' }, // Yellow background with black text
    { background: '#F33838', text: '#FFFFFF' }, // Red background with white text
    { background: '#FACC3F', text: '#000000' }, // Yellow background with black text
    { background: '#F33838', text: '#FFFFFF' }, // Red background with white text
    { background: '#FACC3F', text: '#000000' }, // Yellow background with black text
    { background: '#F33838', text: '#FFFFFF' }, // Red background with white text
    { background: '#FACC3F', text: '#000000' }, // Yellow background with black text
    { background: '#F33838', text: '#FFFFFF' }, // Red background with white text
  ];
  return colors[index % colors.length]; // Ensure colors loop if prizes exceed color list length
};

const data = prizes.map((prize, index) => {
  const { background, text } = getColorByIndex(index);
  return {
    option: prize.name,
    style: {
      backgroundColor: background, // Assign the background color
      textColor: text // Assign the corresponding text color
    }
  };
});


const ZoomedPrizeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '50%',
  transition: 'transform 0.5s ease-in-out',
  transform: 'scale(1.5)',
}));

const SpinningWheel = ({ prize, onSpinFinish, resetGame }) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeIndex, setPrizeIndex] = useState(null);
  const [zoomPrize, setZoomPrize] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (prize) {
      const prizeIdx = prizes.findIndex(item => item.name.toLowerCase() === prize.toLowerCase());
      if (prizeIdx !== -1) {
        setPrizeIndex(prizeIdx);
        setMustSpin(true);
      }
    }
  }, [prize]);

  const handlePrizeDefined = () => {
    setMustSpin(false);
    const wonPrize = prizes[prizeIndex];
    setZoomPrize(wonPrize);
    if (wonPrize.name === 'Try Again') {
        setOpenModal(true);
    }
    if (onSpinFinish) {
        onSpinFinish();
    }
};


  const handleCloseAndReset = () => {
    setOpenModal(false);
    setZoomPrize(null);
    resetGame();
  };

  return (
    <Box textAlign="center" id='sp-wheel'>
      {zoomPrize ? (
        <div className='align-center'>
          <img src={zoomPrize.name === 'Try Again' ? tryAgainImage : keniFituar} alt="Prize" className='prize-svg' />
          <ZoomedPrizeContainer className='zoomed-prize' style={{ backgroundImage: `url(${zoomPrize.image})`, height: '400px', width: '400px' }} />
        </div>
      ) : (
        <section className='h-custom align-center' style={{ transform: 'scale(1.2)' }}>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeIndex}
            data={data}
            onStopSpinning={handlePrizeDefined}
            backgroundColors={['#fafafa', '#fafafa']}
            textColors={['#fafafa']}
            outerBorderWidth={1}
            outerBorderColor="#FACC3F"
            radiusLineWidth={0.3}
            radiusLineColor='#fafafa'
            spinDuration={1}
          />
        </section>
      )}

<Modal
    open={openModal}
    onClose={handleCloseAndReset}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
    className='modal-prize'
>
    <Box
        id='fs'
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'var(--Gray-White, #FFF)',
            border: 'none',
            borderRadius: '8px',
            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 20px 20px 0px rgba(0, 0, 0, 0.08)',
            p: 4,
        }}
    >
        <Typography id="modal-title" variant="h6" component="h2">
            Provoni përsëri
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
            Faleminderit që morët pjesë në shortin tonë të çmimeve! Edhe pse nuk fituat këtë herë, ne jemi të kënaqur t'ju bëjmë të ditur se jeni ende në garë për çmimin tonë të madh - një makinë të re!
        </Typography>
        <div className='flex-end'>
            <Button onClick={handleCloseAndReset} sx={{ mt: 2 }} variant="contained">
                Mbylleni
            </Button>
        </div>
    </Box>
</Modal>

    </Box>
  );
};

export default SpinningWheel;