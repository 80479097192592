import React from 'react';

const OutOfService = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20vh' }}>
      <h1>We are out of service at this moment</h1>
      <p>Please check back later.</p>
    </div>
  );
};

export default OutOfService;
