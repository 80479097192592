import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, Button, TablePagination
} from '@mui/material';
import { styled } from '@mui/system';
import * as XLSX from 'xlsx'; // Import XLSX library
import './Dashboard.css';
import Arrow from "./back-arrow.svg"
import { useNavigate } from 'react-router-dom';
import Logo from "../../logo.svg"

const DashboardContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: '100vh',
  padding: theme.spacing(3),
  backgroundColor: '#fff',
  position: 'relative',
  maxWidth: '1200px', // Ensure the container does not exceed the viewport width
  margin: '0 auto', // Center the container horizontally
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 650,
}));

const LoserRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#f8d7da', // Light red background for losers
}));

const Contenders = () => {
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [filter, setFilter] = useState({
    code: '',
    prize: '',
    winnersOnly: false,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get('/api/admin/entries', {
            headers: {
              'x-auth-token': token
            }
          });
          const data = Array.isArray(response.data) ? response.data : []; // Ensure it's an array
          setEntries(data);
          setFilteredEntries(data);
        } else {
          throw new Error('No token found');
        }
      } catch (error) {
        setEntries([]);
        setFilteredEntries([]);
      }
    };
  
    fetchEntries();
  }, []);
  

  useEffect(() => {
    applyFilters();
  }, [filter, entries]);

  const applyFilters = () => {
    let filtered = entries;

    if (filter.code) {
      filtered = filtered.filter(entry => entry.code.toLowerCase().includes(filter.code.toLowerCase()));
    }

    if (filter.prize) {
      filtered = filtered.filter(entry => entry.prize && entry.prize.toLowerCase().includes(filter.prize.toLowerCase()));
    }

    if (filter.winnersOnly) {
      filtered = filtered.filter(entry => entry.prize && entry.prize !== 'Try Again');
    }

    setFilteredEntries(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExportToExcel = () => {
    const exportData = nonWinners.map(entry => ({
      'Emri i plotë': entry.fullName,
      Email: entry.email,
      'Numri i Telefonit': entry.phone,
      Adresa: entry.address,
      Kodi: entry.code,
      Çmimi: entry.prize,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Garues per premierë');
    XLSX.writeFile(workbook, 'Garues_per_premiere.xlsx');
  };

  const navigate = useNavigate();
  
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const nonWinners = filteredEntries.filter(entry => !entry.prize || entry.prize === 'Try Again');

  return (
    <DashboardContainer className='d-container admin-wrapper'>
        <a href='/'>
      <img src={Logo} className='logo-admin' />
      </a>
      <div className='flex-start' onClick={handleBack} style={{ cursor: 'pointer' }}>
      <img src={Arrow} alt="Back" />
    </div>
    <br />
      <div className='flex-start'>
      <Box sx={{ mb: 2 }}>
        <Button variant="contained" color="primary" onClick={handleExportToExcel}>
          Eksporto Në Excel
        </Button>
      </Box>
      </div>
      <Paper className='dashboard-paper'>
        <Typography variant="h5" gutterBottom>
          Garues per premierë
        </Typography>
        <TableContainer>
          <StyledTable className='styled-table'>
            <TableHead>
              <TableRow>
                <TableCell>Emri i plotë</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Numri i Telefonit</TableCell>
                <TableCell>Adresa</TableCell>
                <TableCell>Kodi</TableCell>
                <TableCell>Çmimi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonWinners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((entry) => (
                <LoserRow key={entry._id} className="LoserRow">
                  <TableCell>{entry.fullName}</TableCell>
                  <TableCell>{entry.email}</TableCell>
                  <TableCell>{entry.phone}</TableCell>
                  <TableCell>{entry.address}</TableCell>
                  <TableCell>{entry.code}</TableCell>
                  <TableCell>{entry.prize}</TableCell>
                </LoserRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={nonWinners.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </DashboardContainer>
  );
};

export default Contenders;