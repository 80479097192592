import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Logo from "../logo.svg"

const LoginBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(3),
}));

const LoginForm = styled('form')(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  backgroundColor: '#fff',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const LoginButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

function Login() {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('/api/auth/login', formData);
      localStorage.setItem('token', res.data.token);
      navigate('/admin'); // Redirect to admin dashboard
    } catch (err) {
      setError('Emrin ose Fjalëkalimin keni shtypur gabim');
    }
  };

  return (
    <LoginBox>
      <LoginForm className='form-c2' onSubmit={handleSubmit}>
      <img src={Logo} className='form-logo' />
        <TextField
          variant="outlined"
          fullWidth
          label="Emri i përdoruesit"
          name="username"
          value={formData.username}
          onChange={handleChange}
          required
          margin="normal"
        />
        <TextField
          variant="outlined"
          fullWidth
          label="Fjalëkalimi"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          margin="normal"
        />
        <LoginButton type="submit" variant="contained" color="primary" fullWidth>
        Identifikohu
        </LoginButton>
        {error && <Alert severity="error">{error}</Alert>}
      </LoginForm>
    </LoginBox>
  );
}

export default Login;