import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, Button, TablePagination
} from '@mui/material';
import { styled } from '@mui/system';
import * as XLSX from 'xlsx'; // Import XLSX library
import './Dashboard.css';
import Arrow from "./back-arrow.svg"
import { useNavigate } from 'react-router-dom';
import Logo from "../../logo.svg"

const DashboardContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: '100vh',
  padding: theme.spacing(3),
  backgroundColor: '#fff',
  position: 'relative',
  maxWidth: '1200px',
  margin: '0 auto',
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 650,
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  flexWrap: 'wrap',
  width: '100%',
}));

const ContenderList = () => {
  const [entries, setEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [filter, setFilter] = useState({
    code: '',
    prize: '',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get('/api/admin/contenders', {
            headers: {
              'x-auth-token': token
            }
          });
          const data = response.data || []; // Default to an empty array if no data
          setEntries(Array.isArray(data) ? data : []); // Ensure the data is an array
          setFilteredEntries(Array.isArray(data) ? data : []); // Ensure the data is an array
        } else {
          throw new Error('No token found');
        }
      } catch (error) {
        setEntries([]); // Set entries to an empty array on error
        setFilteredEntries([]); // Set filteredEntries to an empty array on error
      }
    };

    fetchEntries();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filter, entries]);

  const applyFilters = () => {
    let filtered = Array.isArray(entries) ? entries : []; // Ensure entries is an array

    if (filter.code) {
      filtered = filtered.filter(entry => entry.code.toLowerCase().includes(filter.code.toLowerCase()));
    }

    if (filter.prize) {
      filtered = filtered.filter(entry => entry.prize && entry.prize.toLowerCase().includes(filter.prize.toLowerCase()));
    }

    setFilteredEntries(filtered);
  };

  const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExportToExcel = () => {
    const exportData = filteredEntries.map(entry => ({
      'Emri i plotë': entry.fullName,
      Email: entry.email,
      'Numri i Telefonit': entry.phone,
      Adresa: entry.address,
      Kodi: entry.code,
      Çmimi: entry.prize,
    }));

    
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pjesëmarrësit');
    XLSX.writeFile(workbook, 'Pjesemarresit.xlsx');
  };
  const navigate = useNavigate();
  
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <DashboardContainer className='w-100'>
        <a href='/'>
      <img src={Logo} className='logo-admin' />
      </a>
       <div className='flex-start' onClick={handleBack} style={{ cursor: 'pointer' }}>
      <img src={Arrow} alt="Back" />
    </div>
      <br />
      <FilterContainer>
        <TextField
          label="Kodi"
          name="code"
          value={filter.code}
          onChange={handleFilterChange}
          variant="outlined"
        />
        <TextField
          label="Çmimi"
          name="prize"
          value={filter.prize}
          onChange={handleFilterChange}
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleExportToExcel}>
          Export to Excel
        </Button>
      </FilterContainer>
      <Paper className='dashboard-paper'>
        <Typography variant="h5" gutterBottom>
          Lista e Pjesëmarrësve
        </Typography>
        <TableContainer>
          <StyledTable className='styled-table'>
            <TableHead>
              <TableRow>
                <TableCell>Emri i plotë</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Numri i Telefonit</TableCell>
                <TableCell>Adresa</TableCell>
                <TableCell>Kodi</TableCell>
                <TableCell>Çmimi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEntries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((entry) => (
                <TableRow key={entry._id}>
                  <TableCell>{entry.fullName}</TableCell>
                  <TableCell>{entry.email}</TableCell>
                  <TableCell>{entry.phone}</TableCell>
                  <TableCell>{entry.address}</TableCell>
                  <TableCell>{entry.code}</TableCell>
                  <TableCell>{entry.prize}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredEntries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </DashboardContainer>
  );
};

const ListAll = () => {
  return (
    <div className='admin-wrapper'>
      <ContenderList />
    </div>
  );
};

export default ListAll;