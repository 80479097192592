import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif', // Use Poppins as the default font
  },
  palette: {
    primary: {
      main: '#BF202F',
    },
    text: {
      primary: '#000000',
    },
  },
});

export default theme;
