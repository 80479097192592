import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import logo from "../logo.svg"

const StyledLink = styled(Link)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  margin: theme.spacing(1),
}));

const NavBar = ({ auth, handleLogout, handleOutOfService }) => {
  return (
        <AppBar className='p-custom'>
        <Toolbar>
        <Typography variant="h6">
          <a href='/'>
            <img src={logo} className='logo' />
          </a>
        </Typography>
        <div style={{ marginLeft: 'auto' }} className='mobile-device'>
          {auth ? (
            <>
             {/*  <Button color="inherit" onClick={handleOutOfService}>
                  Out of Service
                </Button> */}
              <Button color="inherit" className='btn-white' onClick={handleLogout}>Shkyç</Button>
            </>
          ) : null}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;