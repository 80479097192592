import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Box, Button, Typography, Alert, Modal } from '@mui/material';
import { styled } from '@mui/system';
import UserForm from './components/UserForm';
import AdminDashboard from './components/AdminDashboard';
import Login from './components/Login';
import SpinningWheel from './components/SpinningWheel';
import Confetti from 'react-confetti';
import NavBar from './components/NavBar';
import Contenders from "./components/Admin/Contenders";
import WinnerList from './components/Admin/List';
import ListAll from './components/Admin/ListAll';
import TermsConditions from './components/TermsConditions';
import Luaj from './titulli.png';
import Map from './components/Admin/Map';
import OutOfService from './components/OutOfService'; // Import the OutOfService component

const RootContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  width: '100vw',
  padding: theme.spacing(3),
}));

const ResultAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const PrizeModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'white',
  boxShadow: 24,
  padding: theme.spacing(4),
  outline: 'none',
}));

function App() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    code: '',
    termsAccepted: false,
  });
  const [result, setResult] = useState('');
  const [showWheel, setShowWheel] = useState(false);
  const [prize, setPrize] = useState('');
  const [spinPrize, setSpinPrize] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showWinnerMessage, setShowWinnerMessage] = useState(false);
  const [isAuthenticated, setAuth] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPlayAgainButton, setShowPlayAgainButton] = useState(false);
  const [isOutOfService, setIsOutOfService] = useState(false); // New state for out of service
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuth(true);
    }
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('/api/auth/check', {
            headers: {
              'x-auth-token': token
            }
          });
          if (response.data.valid) {
            setAuth(true);
            if (location.pathname === '/login') {
              navigate('/admin');
            }
          } else {
            setAuth(false);
            if (location.pathname === '/admin') {
              navigate('/login');
            }
          }
        } catch (error) {
          setAuth(false);
          if (location.pathname === '/admin') {
            navigate('/login');
          }
        }
      } else {
        setAuth(false);
        if (location.pathname === '/admin') {
          navigate('/login');
        }
      }
    };

    checkAuth();
  }, [location.pathname, navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(false);
    navigate('/login');
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateFormData = () => {
    if (!formData.fullName || !formData.email || !formData.phone || !formData.address || !formData.code || !formData.termsAccepted) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (formData) => {
    if (!validateFormData()) {
      setResult('Të gjitha fushat janë të detyrueshme.');
      return;
    }
  
    try {
      const response = await axios.post('/api/userEntries/submit', formData);
      
      // Log the response for debugging
      console.log(response.data);
  
      // Check the server response message and translate it to Albanian
      if (response.data.message === 'Invalid code') {
        setResult('Ky kod nuk ekziston');
      } else if (response.data.message === 'Code has already been used') {
        setResult('Ky kod nuk mund të përdoret më shumë se një herë.');
      } else {
        setPrize(response.data.prize || 'Provo përsëri'); // Default message in Albanian
        setResult(response.data.message);
        setShowForm(false);
        setShowWinnerMessage(true);
  
        setTimeout(() => {
          setShowWinnerMessage(false);
          setShowWheel(true);
        }, 1000);
      }
    } catch (error) {
      // Log the exact error message to debug
      console.error("Error:", error);
  
      // If there is a server response with an error message
      if (error.response && error.response.data && error.response.data.message) {
        setResult(error.response.data.message);  // Display the error message from the server
      } else {
        setResult('Gabim në përpunimin e kërkesës. Ju lutem provoni përsëri.');  // General error message in Albanian
      }
      setShowWheel(false);
    }
  };
  

  const handleSpinFinish = () => {
    setSpinPrize(prize);

    // Show confetti only if the user won a prize
    if (prize !== 'Try Again') {
      setShowConfetti(true);

      // Delay showing the modal and the play again button for 3 seconds after the prize is revealed
      setTimeout(() => {
        setModalOpen(true); // Open the modal after 3 seconds
        setShowConfetti(false); // Optionally, stop confetti when the modal opens
        setShowPlayAgainButton(true); // Show the play again button after 3 seconds
      }, 3000);
    } else {
      // If no prize, show the play again button immediately
      setShowPlayAgainButton(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    navigate('/'); // Redirect to the homepage
  };

  const resetGame = () => {
    setFormData({
      fullName: '',
      email: '',
      phone: '',
      address: '',
      code: '',
      termsAccepted: false,
    });
    setResult('');
    setShowWheel(false);
    setPrize('');
    setSpinPrize('');
    setShowForm(true);
    setShowConfetti(false);
    setModalOpen(false); // Close the modal when resetting the game
    setShowPlayAgainButton(false); // Hide the play again button on reset
  };

  const isDashboard = location.pathname === '/admin';

  return (
    <div className="background-container">
      <div className="bg"></div>
      <NavBar 
        auth={isAuthenticated} 
        handleLogout={handleLogout} 
        isAdmin={true}  // Assuming this is determined from your auth logic
        handleOutOfService={() => setIsOutOfService(true)} // Admin only button handler
      />
      <RootContainer className="content">
        <Routes>
          {isOutOfService ? (
            <Route path="*" element={<OutOfService />} />
          ) : (
            <>
              <Route path="/" element={
                showForm ? (
                  <section className='align-center h-custom'>
                    <img src={Luaj} alt="Logo" className='logo-form' />
                    <UserForm formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                    {result && (
                      <ResultAlert severity={result.includes('Congratulations') ? 'success' : 'error'}>
                        {result}
                      </ResultAlert>
                    )}
                  </section>
                ) : (
                  <>
                    {showWheel && <SpinningWheel prize={prize} onSpinFinish={handleSpinFinish} resetGame={resetGame} />}
                    {spinPrize && showPlayAgainButton && ( 
                      <Box mt={3} textAlign="center">
                        <Button variant="contained" color="primary" onClick={resetGame}>
                          Luaj Përsëri
                        </Button>
                      </Box>
                    )}
                    {showConfetti && (
                      <div className="confetti-container">
                        <Confetti />
                      </div>
                    )}
                  </>
                )
              } />
              <Route path="/login" element={<Login setAuth={setAuth} />} />
              <Route path="/admin" element={isAuthenticated ? <AdminDashboard /> : <Navigate to="/login" />} />
              <Route path="/test" element={<SpinningWheel />} />
              <Route path="/contenders" element={isAuthenticated ? <Contenders /> : <Navigate to="/login" />} />
              <Route path="/winners" element={isAuthenticated ? <WinnerList /> : <Navigate to="/login" />} />
              <Route path="/all-contenders" element={isAuthenticated ? <ListAll /> : <Navigate to="/login" />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/map" element={isAuthenticated ? <Map /> : <Navigate to="/login" />} />
            </>
          )}
        </Routes>

        {/* Modal for displaying the prize */}
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="prize-modal-title"
          aria-describedby="prize-modal-description"
        >
          <PrizeModalBox className='modal-win'>
            <Typography id="prize-modal-title" variant="h6" component="h2">
              Urime!
            </Typography>
            <Typography id="prize-modal-description" sx={{ mt: 2 }}>
              Ju keni fituar: {spinPrize}
            </Typography>
            <br />
            <Typography>
              Për të marrë çmimin tuaj, ju lutemi vizitoni zyrën tonë me produktin që përmban kodin fitues. Sigurohuni që ta sillni produktin me kodin të dukshëm në mënyrë që ta verifikojmë. Pasi të verifikohet, ju do të merrni shpërblimin tuaj në vend!
              <br /><br />
              Adresa e zyrës sonë:
              <br /><br />
              Sllatinë e Madhe - Essi Group
              <br /><br />
              Ne jemi të ngazëllyer t'ju shohim së shpejti dhe t'ju dhurojmë shpërblimin tuaj të merituar! Nëse keni ndonjë pyetje, mos ngurroni të na kontaktoni në +383 49 171 778.
            </Typography>
            <Box mt={3} textAlign="center">
              <Button variant="contained" color="primary" onClick={handleCloseModal}>
                Mbylleni
              </Button>
            </Box>
          </PrizeModalBox>
        </Modal>
      </RootContainer>
    </div>
  );
}

export default App;
