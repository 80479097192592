import React from 'react'
import Cards from './Admin/Cards'
import Logo from "../logo.svg"
import BarCharts from './Admin/BarCharts'
import Map from "./Admin/Map"

const AdminDashboard = () => {
  return (
    <>
      <section className='admin-wrapper'>
      <a href='/'>
      <img src={Logo} className='logo-admin' />
      </a>
        <Map />
        <Cards />
      </section>
    </>
  )
}

export default AdminDashboard