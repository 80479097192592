import React from 'react'
import { useNavigate } from 'react-router-dom';
import Arrow from "./Admin/back-arrow.svg"

const TermsConditions = () => {

    const navigate = useNavigate();

    const handleBack = () => {
      navigate(-1); // Navigate back to the previous page
    };

  return (
        <section id="terms-and-conditions">
            <div className='flex-start mobile-device' onClick={handleBack} style={{ cursor: 'pointer' }}>
      <img src={Arrow} alt="Back" className='back-arrow' />
    </div>
        <br />
    <h1 className='t-header'>Termat & Kushtet</h1>
    <br />
    <br />
    <h2>1. Të përgjithshme</h2>
    <p>
        1.1 Këto Terme dhe Kushte rregullojnë pjesëmarrjen tuaj në lojën shpërblyese Don Soup në website. Duke marrë pjesë në lojë, ju pranoni të pajtoheni me këto Terme dhe Kushtet.
    </p>
    <p>
        1.2 Don Soup rezervon të drejtën të modifikojë këto Terme dhe Kushtet në çdo kohë pa njoftim paraprak. Pjesëmarrja e vazhdueshme në lojë pas çdo modifikimi të tillë përbën pranimin tuaj të Termeve dhe Kushteve të reja.
    </p>

    <h2>2. Pjesëmarrja</h2>
    <p>
        2.1 Pjesëmarrja në lojë është e hapur për të gjithë individët që kanë blerë një produkt të Don Soup me një kod të vlefshëm.
    </p>
    <p>
        2.2 Çdo produkt do të përmbajë një kod unik, i cili mund të perdoret në website-in tonë për të përcaktuar nëse jeni fitues.
    </p>
    <p>
        2.3 Për të marrë pjesë, duhet të paraqisni kodin saktësisht siç shfaqet në produkt. Kodet që janë ndryshuar, dëmtuar ose të palexueshëm nuk do të pranohen.
    </p>

    <h2>3. Fitues</h2>
    <p>
        3.1 Loja ka kode të paracaktuara fituese dhe vetëm këto kode do të rezultojnë në një çmim.
    </p>
    <p>
        3.2 Nëse vendosni një kod fitues, do të njoftoheni menjëherë në aplikacionin e internetit.
    </p>
    <p>
        3.3 Për të kërkuar çmimin tuaj, duhet ta paraqisni produktin me kodin fitues si dëshmi blerjeje. Dështimi për të paraqitur produktin mund të rezultojë në diskualifikim dhe humbje të çmimit.
    </p>
    <p>
        3.4 Çmimet nuk janë të transferueshme dhe nuk mund të shkëmbehen me para në dorë ose me ndonjë alternativë tjetër.
    </p>

    <h2>4. Pretendimi i Çmimeve</h2>
    <p>
        4.1 Për të kërkuar çmimin tuaj, ndiqni udhëzimet e dhëna në aplikacionin e internetit pasi të konfirmohet kodi juaj fitues.
    </p>
    <p>
        4.2 Don Soup rezervon të drejtën për të verifikuar vlefshmërinë e çdo kodi fitues dhe produktit shoqërues përpara se të jepni ndonjë çmim.
    </p>

    <h2>5. Kufizimi i përgjegjësisë</h2>
    <p>
        5.1 Don Soup nuk do të jetë përgjegjës për asnjë humbje, dëmtim ose lëndim që rezulton nga pjesëmarrja në lojë, përveç rasteve të neglizhencës së rëndë ose sjelljes së keqe të qëllimshme.
    </p>
    <p>
        5.2 Don Soup nuk është përgjegjës për ndonjë çështje teknike, duke përfshirë, por pa u kufizuar në, dështimet e rrjetit, gabimet e sistemit ose prishjen e të dhënave, që mund të ndikojnë në pjesëmarrjen tuaj në lojë.
    </p>

    <h2>6. Privatësia</h2>
    <p>
        6.1 Duke marrë pjesë në lojë, ju pranoni mbledhjen dhe përdorimin e informacionit tuaj personal për qëllime të administrimit të lojës dhe dhënies së çmimeve.
    </p>
    <p>
        6.2 Informacioni juaj personal nuk do të përdoret për asnjë qëllim tjetër pa pëlqimin tuaj.
    </p>
</section>
  )
}

export default TermsConditions